import React, { Fragment, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { CentralTextBlock, MetaData, QuickForm } from '../components';
import HeroBanner from '../components/HeroBanner';
import useSpaces from '../utils/hooks/useSpaces';
import Features from '../components/Features';
import SpaceOverview from '../components/SpaceOverview';
import ButtonCard from '../components/ButtonCard';
import Button from '../components/Button';
import useStaff from '../utils/hooks/useStaff';
import Team from '../components/Team';
import Testimonial from '../components/Testimonial';
import LatestNews from '../data/LatestNews';
import CTA from '../components/CTA';
import SpaceCTA from '../components/SpaceCTA';
import styled from 'styled-components';
import BookTour from '../components/BookTour';
import Modal from '../components/Modal';

type Props = {
	pageContext: {
		id: number;
	};
};

const ComingSoon = styled(CentralTextBlock)`
	margin-bottom: ${({ theme }) => theme.spacing[12]};
`;

const Space: React.FC<Props> = ({ pageContext: { id } }) => {
	const [openModal, setOpenModal] = useState(false);
	const { findOne } = useSpaces();

	const space = useMemo(() => findOne({ wordpress_id: id }), [id]);
	// console.log({ space });

	if (!space?.node?.acf) return null;

	const {
		title,
		slug,
		acf: {
			meta_title,
			meta_description,
			cta,
			header,
			features,
			overview_title,
			overview_text,
			overview_image,
			circular_logo,
			team,
			testimonial,
			coming_soon,
			text_sections,
			email,
			...acf
		},
	} = space?.node;

	//console.log('teteeee', text_sections);

	const calendly = cta?.calendly_link;
	const linkText = cta?.call_link_text ?? "Book a tour";

	const spaceTitle = title?.replace(': Coming Soon', '');

	//console.log('Space', { calendly, spaceTitle });

	return (
		<>
			<MetaData title={meta_title || title} description={meta_description} />

			{calendly ? (
				<HeroBanner
					title={header?.header_title}
					images={header?.header_images?.map((item) => ({
						image: {
							source_url: item?.image?.source_url,
						},
						focus: item?.focus,
					}))}
					pageTitle={title}
					linkText={linkText}
					link={calendly}
					//linkPopup={true}
					//cornerImage={header?.corner_image}
				/>
			) : (
				<HeroBanner
					title={header?.header_title}
					images={header?.header_images?.map((item) => ({
						image: {
							source_url: item?.image?.source_url,
						},
						focus: item?.focus,
					}))}
					pageTitle={title}
					linkText={linkText}
					linkPopup={true}
					linkOnClick={() => setOpenModal(true)}
					//cornerImage={header?.corner_image}
				/>
			)}

			{coming_soon ? (
				<>
					{/* <ComingSoon title={"Coming soon..."} text={text} /> */}

					{/* {vacancies ? (
						<TitleSection
							title={"Vacancies"}
							color={"grey"}
							padTop
							content={
								<div className="card-row w-row">
									{location.vacancies.map((v) => (
										<div
											className="card-row-col1 w-col w-col-4"
											key={v.title}
										>
											<NewsCard
												title={v.title}
												text={v.text}
												link={"/contact-us"}
											/>
										</div>
									))}
								</div>
							}
						/>
					) : (
						<TitleSection style={{ display: "none" }} />
					)} */}
				</>
			) : (
				<>
					<Features features={features?.features} columns={5} />

					<SpaceOverview
						title={overview_title ?? ''}
						text={overview_text ?? ''}
						image={overview_image}
						phone={space.node.acf.telephone}
						email={space.node.acf.email}
						logo={circular_logo}
					/>

					{!!team?.team?.length && (
						<Team
							title="Team"
							ids={team?.team.map((item) => item?.wordpress_id)}
						/>
					)}

					{(testimonial?.testimonial_testimonial ||
						testimonial?.testimonial_community_member) && (
						<Testimonial
							quote={testimonial.testimonial_testimonial}
							author={{
								name: testimonial.testimonial_name,
								company: testimonial.testimonial_company,
							}}
							image={testimonial.testimonial_image?.source_url}
							memberId={testimonial.testimonial_community_member}
						/>
					)}

					<LatestNews limit={3} categorySlug={slug} />

					<SpaceCTA
						spaceTitle={spaceTitle || ''}
						tourEmail={email}
						calendly={space.node.acf.cta?.calendly_link}
					/>

					{text_sections?.map((item, i) => (
						<SpaceOverview
							reverse={!!(i % 2)}
							title={item?.title}
							image={{
								source_url: item?.image?.source_url,
								alt_text: item?.image?.alt_text,
							}}
							text={item?.text}
							key={item?.title}
						/>
					))}

					<CTA
						hide={cta?.call_hide}
						title={cta?.call_title}
						text={cta?.call_text}
						link={cta?.call_link}
						linkText={cta?.call_link_text}
					/>
				</>
			)}

			{/* <BookTour
				spaceTitle={title}
				open={tourModal}
				onClose={() => setTourModal(false)}
			/> */}
			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				{typeof openModal === 'string' ? (
					<div
						dangerouslySetInnerHTML={{
							__html: openModal,
						}}
					/>
				) : (
					<QuickForm
						content={{ title: 'Contact us to book a tour', space: spaceTitle }} // {tourModal}
						//page={location.pathname}
						onSend={() => setOpenModal(false)}
					/>
				)}
			</Modal>
		</>
	);
};

export default Space;
