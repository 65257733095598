import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import useStaff from '../../utils/hooks/useStaff';
import Container from '../Container';
import PersonCard from '../PersonCard';
import ThreeCol from '../ThreeCol';
import Title from '../Title';
import TitleSection from '../TitleSection';

type Props = {
	title?: string;
	ids: Array<number | undefined>;
};

const Team: React.FC<Props> = ({ title = 'Team', ids }) => {
	const { findOne } = useStaff();

	const staff = useMemo(() => {
		return ids.filter(Boolean).map((id) => findOne({ wordpress_id: id }));
	}, [ids]);

	return (
		<TitleSection
			title={title}
			containerSize="sm"
			content={
				<ThreeCol>
					{staff.map((item) => (
						<Fade key={item?.node.wordpress_id}>
							<PersonCard
								name={item?.node.title}
								image={{
									source_url: item?.node.featured_media?.source_url,
									alt_text: item?.node.title,
								}}
								role={item?.node.acf?.role}
								bio={item?.node.content}
							/>
						</Fade>
					))}
				</ThreeCol>
			}
		/>
	);
};

export default Team;
