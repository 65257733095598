import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

import Btn from '../Button';
import Card from '../Card';
import Title from '../Title';

type Shapes = 'triangle' | 'circle';

type Props = {
	buttonText: string;
	href?: string;
	onClick?: () => void;
	shape?: Shapes;
	title: string;
};

type CardProps = {
	shape: Shapes;
};

const Button = styled(Btn)`
	width: 195px;
	max-width: 100%;
`;

const Wrapper = styled(Card)<CardProps>`
	align-items: flex-start;
	padding: ${({ theme }) => `${theme.spacing[11]} ${theme.spacing[10]}`};
	background-image: ${({ shape }) => {
		if (shape === 'circle') return 'url(/images/corner-circles.svg)';
		return 'url(/images/corner-triangles.svg)';
	}};
	background-position: right bottom;
	background-repeat: no-repeat;

	@media ${breakpoints.mobileLand} {
		padding-right: ${({ theme }) => theme.spacing[7]};
		padding-left: ${({ theme }) => theme.spacing[7]};
	}
`;

const ButtonCard: React.FC<Props> = ({
	title,
	href,
	shape = 'triangles',
	onClick,
	buttonText,
}) => {
	return (
		<Wrapper shape={shape}>
			{title && (
				<Title as="h4" color="dark" size="md">
					{title}
				</Title>
			)}
			<Button href={href} onClick={onClick} rounded>
				{buttonText}
			</Button>
		</Wrapper>
	);
};

export default ButtonCard;
