import React, { useState } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import BookTour from '../BookTour';
import ButtonCard from '../ButtonCard';
import Container from '../Container';
import QuickForm from '../QuickForm';
import Modal from '../Modal';

type Props = {
	spaceTitle: string;
	tourEmail?: string;
	calendly?: string;
};

const Wrapper = styled.div`
	padding-top: ${({ theme }) => theme.spacing.sectionPaddingSm};
	padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingSm};
	background: ${({ theme }) => theme.colors.whiteish};

	${Container} {
		display: grid;
		grid-gap: ${({ theme }) => theme.spacing.gutter};
		grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));

		> * {
			display: flex;
			> * {
				width: 100%;
			}
		}
	}
`;

const SpaceCTA: React.FC<Props> = ({ spaceTitle, tourEmail, calendly }) => {
	const [tourModal, setTourModal] = useState(false);

	//console.log('SpaceCTA', { calendly });

	return (
		<Wrapper>
			<Container size="sm">
				<Fade>
					{calendly ? (
						<ButtonCard
							title="Book a free tour"
							buttonText="Book now"
							shape="circle"
							href={calendly}
						/>
					) : (
						<ButtonCard
							title="Book a free tour"
							buttonText="Contact us"
							shape="circle"
							onClick={() => setTourModal(true)}
						/>
					)}
				</Fade>

				<Fade>
					<ButtonCard
						title="Join our online community"
						buttonText="Register now"
						shape="triangle"
						href="https://townsq.circle.so/c/welcome/"
					/>
				</Fade>
			</Container>
			<Modal open={tourModal} onClose={() => setTourModal(false)}>
				{typeof tourModal === 'string' ? (
					<div
						dangerouslySetInnerHTML={{
							__html: tourModal,
						}}
					/>
				) : (
					<QuickForm
						content={{ title: 'Contact us to book a tour', space: spaceTitle }} // {tourModal}
						//page={location.pathname} // comment
						onSend={() => setTourModal(false)}
					/>
				)}
			</Modal>
		</Wrapper>
	);
};

export default SpaceCTA;
