import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import breakpoints from '../../styles/breakpoints';
import Container from '../Container';
import ShapeImage from '../ShapeImage';
import Title from '../Title';
import UserContent from '../UserContent';

type Props = {
	title?: string;
	text?: string;
	phone?: string;
	email?: string;
	image?: {
		source_url?: string;
		alt_text?: string;
	};
	logo?: {
		source_url?: string;
		alt_text?: string;
	};
	reverse?: boolean;
};

const Image = styled(ShapeImage)`
	position: relative;
	top: -1rem;
`;

const Content = styled(UserContent)`
	p {
		font-size: ${({ theme }) => theme.fontSize.bodyXl};
	}

	img {
		display: inline-block;
		max-width: 90px;
		max-height: 90px;
		width: auto;
		height: auto;
		margin: 10px;
	}

	+ * {
		margin-top: ${({ theme }) => theme.spacing[8]};
	}
`;

const Icon = styled.a`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.textFaded};
	font-size: ${({ theme }) => theme.fontSize.bodyXl};
	text-decoration: none;

	svg {
		position: relative;
		top: -0.2rem;
		width: 20px;
		height: auto;
		margin-right: ${({ theme }) => theme.spacing[4]};
		fill: ${({ theme }) => theme.colors.primary};
	}

	+ * {
		margin-top: ${({ theme }) => theme.spacing[3]};
	}
`;

type WrapperProps = Pick<Props, 'reverse'>;
const Wrapper = styled.div<WrapperProps>`
	position: relative;
	background: ${({ theme, reverse }) =>
		theme.colors[reverse ? 'white' : 'whiteish']};

	${Container} {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
		padding-top: ${({ theme }) => theme.spacing.sectionPadding};
		padding-bottom: ${({ theme }) => theme.spacing.sectionPadding};
	}

	.text {
		flex: 1;
		max-width: 450px;
		${({ theme, reverse }) => `
      padding-right: ${reverse ? 0 : theme.spacing[8]};
      padding-left: ${reverse ? theme.spacing[8] : 0};
    `}
	}

	@media ${breakpoints.mobileLand} {
		${Container} {
			flex-direction: column-reverse;
			padding-top: ${({ theme }) => theme.spacing.sectionPaddingMobile};
			padding-bottom: ${({ theme }) => theme.spacing.sectionPaddingMobile};
			align-items: center;
		}

		${Image} {
			top: initial;
		}

		.text {
			padding: ${({ theme }) => theme.spacing[8]} 0 0 0;
		}
	}
`;

const CircleLogo = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	z-index: 2;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const ImagesWrapper = styled.div`
	position: relative;
`;

const SpaceOverview: React.FC<Props> = ({
	title,
	text,
	image,
	phone,
	email,
	reverse,
	logo,
}) => {
	return (
		<Wrapper reverse={reverse}>
			<Container size="sm">
				<Fade>
					<div className="text">
						<Title as="h3" size="md">
							{title}
						</Title>
						<Content content={text} />

						{phone && (
							<Icon href={`tel:${phone}`}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
								</svg>
								{phone}
							</Icon>
						)}

						{email && (
							<Icon href={`mailto:${email}`}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
								</svg>
								{email}
							</Icon>
						)}
					</div>
				</Fade>

				<Fade>
					{image?.source_url && (
						<ImagesWrapper>
							{logo && (
								<CircleLogo>
									<img src={logo?.source_url} alt="" />
								</CircleLogo>
							)}

							<Image
								reverse={reverse}
								image={{
									src: image.source_url,
									alt: image.alt_text,
								}}
								shape="arrows"
								size="lg"
							/>
						</ImagesWrapper>
					)}
				</Fade>
			</Container>
		</Wrapper>
	);
};

export default SpaceOverview;
